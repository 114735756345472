import axios from 'axios';

axios.defaults.headers.common['Accept'] = 'application/json' // for all requests
axios.defaults.validateStatus = false;

const api_url = process.env.VUE_APP_ROOT_API + 'api/';
const second_server = "https://inscricoesapi.tkx.com.br/"+ 'api/';
export default {
    name: 'Orders',
    get_events(id,token) {
        return axios.get(api_url + 'auth/ver-detalhes/'+id,{ headers: { 'Authorization': 'Bearer ' + token } });
    },
    validate_code(lote_id,code, token){
        return axios.post(second_server + 'auth/codigo',{"lot_id": lote_id,"codigo": code},{ headers: { 'Authorization': 'Bearer ' + token } });
    },
    get_itens(orders,token){
        return axios.post(api_url + 'auth/carrinho',{"orders": orders},{ headers: { 'Authorization': 'Bearer ' + token } });
    },
    make_order(infos,token){
        return axios.post(second_server + 'auth/pedido',infos,{ headers: { 'Authorization': 'Bearer ' + token } });
    },
    send_mail(id,token) {
        return axios.get(api_url + 'auth/enviar-inscricao/'+id,{ headers: { 'Authorization': 'Bearer ' + token } });
    },
}
